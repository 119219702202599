var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', {
    attrs: {
      "w": "100%",
      "mx": "auto",
      "p": ['1rem', '0px']
    }
  }, [_c('c-box', {
    attrs: {
      "h": "100vh",
      "background": "#FFFFFF",
      "border": "1px solid #f2f2f2",
      "box-shadow": "4px 4px 50px 5px rgba(0, 0, 0, 0.05)",
      "border-radius": "16px",
      "w": "100%",
      "p": "8px",
      "align": "center"
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }