<template>
  <c-box
    w="100%"
    mx="auto"
    :p="['1rem', '0px']"
  >
    <c-box
      h="100vh"
      background="#FFFFFF"
      border="1px solid #f2f2f2"
      box-shadow="4px 4px 50px 5px rgba(0, 0, 0, 0.05)"
      border-radius="16px"
      w="100%"
      p="8px"
      align="center"
    />
  </c-box>
</template>

<script>
export default {
  name: 'BlankPage',
}
</script>
